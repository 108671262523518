

#navigation { background:$color-white; display: flex; min-height: 75px; padding:0 15px; z-index: 999; width:100%; overflow: visible;
            ul#links { display: flex; margin:11px 0 0 0;
                     li     { padding:0 5px; text-align: center; width: auto;
                            .btnPrimary { margin: 0; min-width: 160px; padding:1.33em 1.66em 1.2em; }
                            }
                     }
            }


.dropdown.menu > li.is-dropdown-submenu-parent > a::after { border-top-color: $colorPrimary!important; }
.dropdown.menu > li.opens-right > .is-dropdown-submenu    { background:#000; border:none; }

#mainnav               { display: inline-flex; flex-grow: 1; margin:30px 0 0 0;
                       a  { color:$colorPrimary; font:13px $font-proximasemibold; text-transform: uppercase; white-space: nowrap; }
                       li { display: inline-block; margin:0; width:auto; }
                       li.special     { position: absolute; top:40px; left:267px; text-align: center;
                                      .wrapper  { align-items:center; background:#f3f5f7; justify-content:center; max-width:390px; min-width:330px; padding:30px; text-align:center; }
                                      header h3 { text-transform:uppercase; font:26px $font-main; margin:30px 0 0; line-height:1;
                                                span { color:$colorPrimary; display: block; font:12px $font-proxima; opacity:0.4; text-transform: uppercase; }
                                                }
                                      a         { display: inline-block; padding:1.33em 1.66em 1.2em; }
                                      img       { height:128px; margin-top:-50px; }
                                      ul li     { padding:0;
                                                img { height:38px; margin:0; }
                                                }
                                      }
                       a::after       { border:none; }
                       .submenu       { box-shadow:0 15px 30px 0 rgba(0,0,0,.05);
                                       li { display: block; }
                                      }
                       .dropdown.menu > li.is-dropdown-submenu-parent > a  { margin:0 15px; padding:0; }
                       .dropdown.menu > li.opens-right > .is-dropdown-submenu { border:none; border-radius: 2px; box-shadow: 0 15px 30px 0 rgba(0,0,0,.05); margin-top:10px; min-height:370px; min-width:640px;
                                                                              header h4 { font:26px $font-main; max-width:220px; }
                                                                              p         { line-height: 20px; max-width:200px; }
                                                                              }
                       .is-dropdown-submenu { border:none; border-radius: 2px; box-shadow: 0 15px 30px 0 rgba(0,0,0,.05); padding: 40px 35px;
                                            a { display: block; margin: 15px 0 15px!important; padding:0; white-space: nowrap; }
                                            a:first-of-type { /* margin-top: 0; */ }
                                            li:last-child a { margin-bottom: 0; }
                                            }
                       }
.sticky.is-stuck           { background: $color-white; }
.sticky.is-stuck.is-at-top { background:$corporateColor; box-shadow: 0 15px 30px 0 rgba(0,0,0,.05); max-height:85px; max-width:none!important; min-height:85px;
                           figure#emblem { margin:0 0 0 20px;
                                         img { margin:10px 0 0 0; max-width:80%; }
                                         }
                           figure#logo img  { margin: 5px 0 0 0; }
                           }

.off-canvas-content .button      { background: transparent; color: #001521; float:left; margin:23px 10px 0 10px; padding:0;
                                 i { font-size:1.8em; }
                                 }
.off-canvas.is-transition-push   { background: #001521; padding:0 30px ; z-index: 9999; }
.off-canvas.is-transition-overlap { background: #001521; height:100vh; padding:0 30px ; z-index: 9999; }
.off-canvas.is-transition-overlap.is-open{ background: #001521; height:100vh; padding:0 30px; z-index: 999999; }

.drilldown                       {
                                 a                     { background: none; border-bottom:1px solid $color-white; font:400 16px $font-main; letter-spacing: 0.02em; padding-left: 0; text-transform: uppercase; }
                                 a:hover               { color:$color-sunflower!important; opacity: 1; }

                                 .is-drilldown-submenu { background: #001521; }
                                 .is-drilldown-submenu-parent > a::after { border-color: transparent transparent transparent #fff; }
                                 .is-drilldown-submenu a { font:17px $font-main, sans-serif; padding-left: 0; padding-right: 0; }
                                 .js-drilldown-back    { padding-right:0; text-align:right; display: inline-block; width:100%; }
                                 .js-drilldown-back a  { background: $color-sunflower; border-radius:5px; border-bottom: none!important; display: inline-block; font-size:0; padding:10px 20px;  }
                                 .js-drilldown-back > a::before { border-color:  transparent #001521 transparent transparent; margin-right:0; }
                                 }

figure.emblem                    { margin:40px 0 40px; text-align: center;
                                 img { min-width:60px; }
                                 }
                                .position-left { width: 450px; transform: translateX(-450px); }
.close-button                   { color: $color-white; font-size:3em;
                                :focus  { color: $color-white!important; }
                                :hover  { color: $color-white!important; }
                                }
                                #offCanvas .social { border: none!important; margin:40px auto!important; max-width: 65%; padding:0!important; text-align: center; width:auto; }



@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) { background: none; height: $size; margin: 0; padding: 0; position: relative; border: 0; width: $size;

  &:before,
  &:after { background: $color; border-radius: $thickness; content: ''; position: absolute; top: ($size - $thickness) / 2; left: 0; right: 0; height: $thickness; }
          &:before { transform: rotate(45deg); }
          &:after  { transform: rotate(-45deg); }
          span     { display: block; }
  }

  .btn-close { border: 0; cursor: pointer; display: flex; flex-flow: column nowrap; height: 80px; justify-content: center; margin: 0; padding: 0; width: 80px; align-items: center; transition: all 150ms; position:absolute; right:0;
             .icon-cross { @include cross(40px, #fff, 3px); }
             &:hover,
             &:focus { transform: rotateZ(90deg); }
             }


            // For screen readers.
            .visually-hidden {
              position: absolute !important;
              clip: rect(1px, 1px, 1px, 1px);
              padding: 0 !important;
              border: 0 !important;
              height: 1px !important;
              width: 1px !important;
              overflow: hidden;
            }

.sticky.is-anchored { z-index: 99999; }

.sticky.is-stuck #navigation { box-shadow: 0 15px 30px 0 rgba(0,0,0,.05); }


/* AJUSTOS TOPBAR */
.newmainnav{ display: flex; flex-grow: 1; padding:0 15px; }
#topcorp   { display: flex; }








/* LANGUAGE SELECTOR DROPDOWN FIX */

#container  { margin: 0 auto; }
nav         { margin: 0; }
nav ul      { padding: 0; margin: 0; list-style: none; position: relative; }
nav ul li   { display:inline-block; }
nav a       { cursor: pointer; display:block; padding:0 10px; color:#FFF; font-size:20px; line-height: 60px; text-decoration:none; }
nav a:hover { }

/* Hide Dropdowns by Default */
nav ul ul   { background: #fff; display: none; position: absolute; top: 60px; /* the height of the main nav */ right: 0; }
/* Display Dropdowns on Hover */
nav ul li:hover > ul { display:inherit; }

/* Fisrt Tier Dropdown */
nav ul ul li { width:170px;	float:none; display:list-item; position: relative; }

/* Second, Third and more Tiers	*/
nav ul ul ul li { position: relative; top:-60px; left:170px; }


#language_selector nav li > a { padding:5px 20px 0; }
#language_selector nav li > a:after { content:''; border-color: #fff transparent transparent!important; border: 5px inset; border-bottom-width: 0; border-top-style: solid; display: inline-block; width: 0; height: 0; position: absolute; right: 5px; top:34px; }
#language_selector nav li > a:only-child:after { content: ''; }
#language_selector nav li > a:hover { background:rgba(0,0,0,.05); }
#language_selector nav a     { height:75px; text-align:center; }
#language_selector nav ul ul { top:75px; z-index: 99999; }
#language_selector nav ul ul li a  { color: #000; font-size: 13px; font-weight: 600; height: auto; line-height:40px; padding:0 10px; text-align: left; text-transform: uppercase;}
#language_selector nav ul ul li a img  { margin:0 10px 0 0; min-width: 18px; }
#language_selector nav ul ul li a:after { border:none!important; }
#language_selector nav li > a img { min-width: 18px; }



/* MAINNAV DROPDOWN FIX */
#container-mainnav { margin:0; flex-grow: 1;
                   #mainnav { margin: 0;
                            a { line-height:76px; }
                            header h4 { font: 26px $font-main; margin:0 0 20px 0; max-width: 220px; }
                            ul li ul  { background: $color-white; border: none; border-radius: 2px; box-shadow: 0 15px 30px 0 rgba(0,0,0,.05); margin-top: 0!important; min-height: 380px; min-width:640px; padding:30px 30px; top: 100%; right: auto; left: 0;
                                      li { display: block; margin:14px 0;
                                         a { line-height: normal!important; }
                                         }
                                      p  { line-height: 20px; max-width: 200px; }
                                      }
                            li.special{
                                      header h3   { margin-bottom:20px; }
                                      .wrapper    { max-width:340px; min-width: 340px; }
                                      .btnPrimary { margin:0; }
                                      }

                            }
                   }





   /* The side navigation menu */
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    left: 0;
    background-color: #111; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }

  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }

  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main {
    transition: margin-left .5s;
    padding: 20px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav { padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }


  #mySidenav { z-index: 999999; }

  #mySidenav ul li { text-align: left; }
  #mySidenav ul li a { border-bottom:1px solid rgba(255,255,255,0.4); padding:20px 20px; }

  #mySidenav ul    { padding:20px 20px; }
