
//* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* TABLETS (portrait) ----------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

.navmobile   { left:15px; top:15px; z-index: 999999999999; }
#wrappertop  { height:75px; }
#videohome   { height:42.2vh!important; margin: 0!important; overflow:hidden;
             iframe { padding:0; }
             }
h2      { font-size:38px; margin:0 0 20px 0; }
#topbar {
        figure#emblem{ margin:10px 0 0 10px;
                     img   { width:75px; }
                     }
         ul.sponsors {
                     a img { width:42px; margin:0 5px; }
                     li:nth-child(2),
                     li:nth-child(3) { margin:0; }
                     }
         div.social  { padding:25px 10px; }
         figure#logo       { margin:29px 0 0 30px; }
         .kalturaplayer    { margin:15px 0 0 10px; }
         #navigation ul#links { margin:16px 0 0 0; }
         #navigation ul#links li .btnPrimary { font-size:12px; min-width:90px!important; padding:1em 1.66em .7em; }
         #language_selector { margin:0; padding:0; right: 15px!important; top:0!important; }
         }

.slider  { height: 560px; overflow: hidden;
         .grid-container { padding:0; }
         div [role="main"]  { max-width: 40em; padding: 280px 60px 0 ; }
         div [role="main"]::after { height:560px; left:auto; width:100%; }
         div [role="article"] > img { left:0; margin:0; width: 100%; }
         .slick-prev { left:60px; top: 30rem }
         .slick-next { left:120px; top: 30rem }
         }

aside#news { padding:40px 20px 10px 20px;
           ul li       { margin-bottom:30px; }
           ul div.info { padding:30px 30px 40px 30px;
                       h3 { min-height: 52px; }
                       }
           .vertodo    { margin:0;
                       .btnSecondary { display: inline-block; text-align: center; width:100%; }
                       }
           }

aside#banners { padding:0;
              ul li { padding:20px;
                    h3     { font-size:18px; line-height: 22px; }
                    figure { margin:0 10px 0 0; width:80px; }
                    }
              a.arrow { right: 10px; }
              }

aside#media {
            .slick-prev { left:0px; top:0; }
            .slick-next { right:0px; top:0; }
            }
aside#rcde-stadium {
                   .box        { margin:0; padding:40px 0; }
                   .emblem img { left:43.5%; max-width: 100px; top: -33%; }
                   ul.imagebox { margin:0 0 40px 0!important;
                               li div.main { bottom: 20px; left: 20px; text-align: left; }
                               li h2 { font-size:20px!important; max-width:75%!important; }
                               }
                   }

aside#club {
           ul.imagebox { margin:30px 0;
                       li div.main { bottom: 20px; left: 20px;
                                   h2 { font-size:20px; max-width:75%; }
                                   }
                       }
           }

aside#app      { padding:30px 5% 0;
               header h4 { font-size:32px; }
               p         { margin:0 0 20px; }
               }

aside#sponsors { padding:40px 0; }

footer         { padding: 30px 40px;
               #contacto    { margin:0; width:100%;
                            ul li { padding:0; text-align: center; }
                            }
               .btnPrimary  { min-width: inherit; }
               article      { padding-bottom:20px; }
               .medium-12 figure { text-align: center;
                                 img { max-width:15%; }
                                 }
               #copyright p { text-align: center; }
               #legal div a { font-size:12px; }
               }


#section-header { height: 174px;
                header { margin-top:50px;
                       h1      { font-size:32px; }
                       h1 span { margin:0;}
                       }
                }
.tabs-title a { padding: 2em 2em 1.5em; }
.accordion-title { padding:1rem; }

#filters li { margin: 0 5px; width: 18.6%;
            .btnSecondary { font-size:11px; padding:1.33em 1.2em 1.2em; }
            }

section#new { padding:20px;
            h1 { font-size: 42px; line-height: 44px; }
            aside#sidebar { padding-bottom:0; padding-top:40px; }
            aside#nextmatch ul#match li img { height: 40px; width: 40px; }
            aside#galeria_news { padding:10px 0 40px !important; }
            .responsive-embed { padding-bottom: 56.3%; }
            }
section#new aside#related { padding:0px 10px; }

aside#nextmatches { margin:10px 0 5px;
                    ul li {
                          h3               { font-size:18px; margin:0; }
                          h4               { font-size:10px; margin:0; }
                          span             { font-size:12px; }
                          figure img       { max-width: 42px !important; }
                          ul li ul li span { background-size:2px; background-position:100% 8px!important; font-size:14px; }
                          small            { font-size:40%; }
                          ul.buttons       { margin:10px 0; }
                          ul.buttons .btnPrimary { font-size:12px; margin:0!important; min-width: inherit; padding:1em; width:100%; }
                          ul.large-up-2 li:last-child { padding:0 5px; }
                          ul.large-up-2 li:first-child{ padding: 0 5px; text-align: center; }
                      }
                  }



/* --------------------------------------------------------------------------------------------------- */
/* MEMBERS ------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

#members {
          article  { margin:0; padding:40px 20px; }
          .tabs-content {
                        .tabs-panel  { padding: 30px;
                                     h3 { font-size: 36px; margin:0; }
                                     h4 { font-size: 22px; margin:0; }
                                     p  { margin: .5em 0; }
                                     }
                        }
          ul#datos { margin:20px 0;
                   li { padding:30px; }
                   li:first-child { border-bottom:1px solid rgba(0, 21, 33, 0.2); border-right:none; }
                   }
          .white { margin:-40px 0 0 0!important; padding: 30px 20px; }
          }

.notas            { margin:0; }
.bigger           { font-size: 40px; margin-bottom:0; }

#player h1        { font-size:34px; line-height: 36px; }

div#form-info { padding:40px 20px; }
#lightgallery { padding:40px 0;
              figure img  { min-height:inherit; }
              .slick-next { right:0; top:50%; }
              .slick-prev { left:0; top:50%; }
              }

#business     {
              article   { margin:0; padding:40px; }
              header h2 { margin-bottom:30px; }
             }

#boxes { margin:40px 0;
       ul li { padding:45px 30px; }
       }
ul[role="list"] li p { margin: 0; }

#simulador .panel { margin: 0 0 20px 0; padding: 40px 40px; }
#simulador aside  { width: 48.1%; }
.btnback          { left: 40px; top: 167px; }

section#homepage .responsive-embed { max-width: 93.7%; padding-bottom: 52.7%; }
#topcorp             { margin:0!important; }
#topbar {
        .kalturaplayer    { margin:19px 0 0 10px; }
        ul.sponsors       { text-align:center; width: 140px; }
        div.social        { /* position: absolute; right:80px; top:-3px; width:200px; */}
        #language_selector{ /* position: absolute; right: 20px; top:-2px; */ }
        }
#offCanvas div.social     { position: relative!important; right:inherit; top:inherit;; width:inherit; }
#sponsors img { max-width: 100px; }

#kaltura_player_302225913 { height: 39.5vh !important; max-width: 47.4rem !important; }

.slider div [role="main"]::after { left:0; }
}





/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* TABLETS (landscape) ---------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
h2                   { font-size:32px; }
aside#banners ul     { margin:0;
                     li  { padding:20px; }
                     }
aside#news           { padding:40px 10px 40px 10px;
                     .large-up-4 > .cell { width: calc(33.33333% - 1.875rem)!important; }
                     li:last-child { /* display:none; */ }
                     }

aside#rcde-stadium .emblem img { left: 44.4%; top:-32%!important; width: 110px; }
aside#rcde-stadium figure div { top: 70%; }

section#homepage {
                 .responsive-embed { max-width:95% !important; padding-bottom: 53.4% !important; }
                 ul.imagebox li  {
                                 h2       { font-size:24px; }
                                 div.main { bottom: 30px; left: 30px; }
                                 }
                 }

#section-header      { height: 220px;
                     header { margin-top: 65px; }
                     header h1 { font-size: 36px; }
                     }


#topbar figure#logo  { margin: 30px 0 0 0; width:205px!important; }
#topbar div.social   { width:205px; }
#navigation ul#links { margin: 11px 0 0 0; min-width: 340px !important; position: absolute; right:10px; }
#topbar ul.sponsors  { width: 160px!important; }
#topcorp             { width:50%!important; }
#btnsmobile          { float:right;}
.sticky.is-stuck.is-at-top figure#emblem { margin: 0; }

#members {
         article { margin:0; padding:40px 0; }
         ul[role="listitem"] li.cell { font-size:16px; padding:30px; }
         header h2 { font-size: 32px; line-height: 38px; }
         }

div#form-info { padding: 40px 30px; }

#boxes ul li figure { width:240px; }

footer               { padding:50px 0 20px 0;
                     #copyright p  { text-align: center; }
                     .social .cell { padding: 0 8px; }
                     }
#kaltura_player_302225913 { height: 39.5vh !important; max-width: 47.4rem !important; }

aside#nextmatches { margin:10px 0 5px;
                    ul li {
                          h3               { font-size:18px; margin:0; }
                          h4               { font-size:10px; margin:0; }
                          span             { font-size:12px; }
                          figure img       { max-width: 42px !important; }
                          ul li ul li span { background-size:2px; background-position:100% 8px!important; font-size:14px; }
                          small            { font-size:40%; }
                          ul.buttons       { margin:10px 0; }
                          ul.buttons .btnPrimary { font-size:12px; margin:0!important; min-width: inherit; padding:1em; width:100%; }
                          ul.large-up-2 li:last-child { padding:0 5px; }
                          ul.large-up-2 li:first-child{ padding: 0 5px; text-align: center; }
                      }
                  }
aside#media .mediacarousel div div div div header h4 { font-size:16px; margin: 20px 0 0px 0; }
aside#app      { padding:30px 5% 0;
               header h4 { font-size:32px; }
               p         { margin:0 0 20px; }
               }
}
