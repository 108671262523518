
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------- LAPTOP SCREEN RESOLUTION --------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-device-width : 768px) and (max-device-width : 1366px) {


}


@media only screen and (min-device-width : 1050px) and (max-device-width : 1650px) {
#navigation ul#links { float: right; margin: 15px 0 0 0; min-width: 275px!important; }
}





/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* MAC BOOK PRO 13 INCH (ISSUES) */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
}




/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* MAC BOOK (ISSUES) */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  #kaltura_player_302225913 { height: 88vh!important; }

}




/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP 1280 * 1024 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 1024px) and (max-width: 1280px) {

section#homepage .responsive-embed { margin: 0 auto; max-width: 97.7%!important; padding-bottom: 55%!important; }
figure#emblem             { margin: 0; margin: 4px 0 0 10px; width: 60px; }
#topbar figure#logo       { margin: 25px 0 0 0; width: 260px; }
#section-header           { height:275px; }
#topbar ul.sponsors a img { border-radius:3px; width: 42px;}
#navigation ul#links      { margin: 10px 0 0 0; min-width: 340px !important; }
.sticky.is-stuck.is-at-top figure#emblem img { max-width: 100%; }
aside#media               {
                          .slick-next { right: 10px; top: 0%; }
                          .slick-prev { left: 10px; top: 0%; }
                          }
aside#rcde-stadium .box   { margin:0; }
aside#app                 { padding: 45px 5% 0; }
aside#sponsors            { padding: 50px 0; }
#topbar div.social        { width: 205px; }
#kaltura_player_302225913 { height: 68.6vh !important; max-width: 78rem !important; }

}



/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP 1440 * 900 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 900px) and (max-width: 1440px) {

section#homepage .responsive-embed { margin: 0 auto; max-width: 97.7%!important; padding-bottom: 55%!important; }
figure#emblem             { margin: 0; margin: 4px 0 0 10px; width: 60px; }
#topbar figure#logo       { margin: 25px 0 0 0; width: 260px; }
#section-header           { height:308px; overflow: hidden; }
#topbar ul.sponsors       { padding:20px 0; text-align: center; }
#topbar ul.sponsors a img { border-radius:3px; width: 60px;}
#navigation ul#links      { margin: 10px 0 0 0; min-width: 340px !important; }
.sticky.is-stuck.is-at-top figure#emblem img { max-width: 100%; }
aside#media               {
                          .slick-next { right: 10px; top: 0%; }
                          .slick-prev { left: 10px; top: 0%; }
                          }
aside#rcde-stadium .box   { margin:0; }
aside#app                 { padding: 45px 5% 0; }
aside#sponsors            { padding: 50px 0; }
#topbar div.social        { width: 205px; }
}



/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP WIDTH MDPI SCREEN 1280 * 800 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 800px) and (max-width: 1280px) {
#topbar figure#logo { margin:27px 0 0 25px; width: 215px; }
#topcorp            { max-width:690px; }
#dinamicsponsors    { padding:auto 10px; width:210px; }
#kaltura_player_302225913 { height: 87.9vh!important; max-width: 78rem !important; }

}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP WIDTH TOUCH 1280 * 950 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 950px) and (max-width: 1280px) {

#kaltura_player_302225913 { height: 73.9vh!important;  }

}



/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP 1680 * 1050 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-device-width : 1050px) and (max-device-width : 1680px) {
#topbar ul.sponsors { width:270px; }
section#homepage .responsive-embed { max-width: 83.8%; padding-bottom: 47.1%; }
#kaltura_player_302225913 { height:75.4vh!important; }
}
