
section#legal    { background:#fff;
                 article         { margin: 0 auto; max-width: 80%; padding: 40px 0; }
                 header h2       { color:$colorSecondary; font: 600 32px $font-main; margin:0 0 20px 0; text-align: center; }
                 header h3       { color:$colorSecondary; font: 600 28px $font-main; text-transform: uppercase; }
                 header h4       { font: 600 16px $font-main; margin: 1em 0; }
                 p               { font-size:16px; margin: 0 0 1rem; width: 100%;
                                 a { border-bottom:2px solid $colorSecondary; color:$colorSecondary!important; font-weight:600!important; }
                                 }
                 ul              { margin:20px 0 40px 0;
                                 li        { color:$colorSecondary; font-size:16px; margin:1em 0; }
                                 li strong { color:$colorSecondary; }
                                 li a      { border-bottom:2px solid $colorSecondary; color:$colorSecondary!important; font-weight:600!important; }
                                 }
                 #section-header { background:$color-black; height:100%; height: 420px; overflow: hidden;
                                 header h2       { color:$color-white; }
                                 .grid-container { left: 0; max-width: none; position: absolute; top:0; width: 100%; }
                                 }
                 table           { margin:20px 0;
                                 td { font:400 16px $font-proxima; }
                                 a  { border-bottom:2px solid $colorSecondary; color:$colorSecondary!important; font-weight:800; }
                                 }
                 }
