
section#homepage { background:#fff; display: inline-block; width:100%;
                 #videohome    { height:114vh; margin:-163px auto 0;
                               iframe { padding-bottom: inherit; }
                               }
                 ul            { margin: 0; }
                 header h1     { color:$colorSecondary; font: 600 40px $font-main; letter-spacing: 0.02em; margin:0 0 20px 0; text-align: left; }
                 header h2     { color:$colorSecondary; font: 600 32px $font-main; text-align: center; }
                 aside#intro   { display: inline-block; padding:60px 40px 40px;
                               p { color:$colorSecondary; font:400 18px $font-proxima; line-height: 24px; margin:0 0 20px 0; text-align: left; }
                               ul { margin:10px auto 0 auto;
                                  li { margin-bottom: 20px;
                                     img       { display: inherit; margin:0 auto 0; max-width: 75%; }
                                     header h3 { color:$colorSecondary; font:400 16px $font-medium; text-align: center; text-transform: uppercase; }
                                     }
                                  }
                               p.btn    { display: inline-block; margin:30px 0 0 0; padding:0; min-height: inherit!important;
                                        a { background:#0081CD; border-radius: 30px; color:#fff; display: inline-block; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                        }
                               }

                 aside#central { background: #001521; display: inline-block; margin:60px 0 0; padding:80px 40px 100px; width: 100%;
                               ul li { padding: 0 30px; }
                               header h2 { color:$color-white; font: 600 42px $font-main; margin:0 0 50px 0; text-align: center; text-transform:uppercase; }
                               header h3 { color:$color-white; font: 600 28px $font-main; text-transform:uppercase; }
                               img       { margin: 0 0 20px 0; }
                               p         { color:$color-white!important; font:400 16px $font-proxima; margin:0 0 20px 0; min-height: 170px; line-height: 24px; }
                               p.btn     { margin:0; padding:0; min-height: inherit!important; text-align: left;
                                         a { background:#0081CD; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                         }
                               }
                 aside#events { background:#F2F5F7; padding:60px;
                              header h2 { margin:0 0 50px 0; text-align: center; }
                              header h4 { color:$colorSecondary; font: 600 38px $font-main; line-height: 1em; margin:0 0 30px 0; text-transform: uppercase; }
                              p.subtitle{ color:$colorSecondary; font: 600 18px $font-main; margin: 0; text-transform: uppercase; }
                              p.date    { color:$colorSecondary; font: 400 18px $font-medium; margin:0 0 40px 0; opacity:.4; }
                              p         { color:$colorSecondary; font:400 18px $font-proxima; margin:0 0 20px 0; }
                              .events_slider { background:#fff; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); padding:60px; }
                              p.btn    { display: inline-block; margin:20px 0 0 0; padding:0; min-height: inherit!important; text-align: left;
                                       a { background:#0081CD; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                       }

                              }
                  aside#stagefront { padding:100px 60px 80px;
                                   figure    { margin:0 0 40px; text-align: center;
                                             img { max-width: 30%; }
                                             }
                                   header h2 { margin:0 0 20px 0; text-align: center; }
                                   p.subtitle{ color:$colorSecondary; font: 400 18px $font-proxima; margin: 0 auto; max-width: 80%; text-align:center; }
                                   }
                               }

p.btn            {
                 a:hover { background: $color-black!important; opacity:1; }
                 }




section#cover { background:#000; display: inline-block; width:100%;
              header h1     { color:$color-white; font: 600 40px $font-main; letter-spacing: 0.02em; margin:0 0 0 0; text-align: center; }
              .caption      { position:absolute; text-align:center; top:50%; width:100%; }
}


/* --------------------------------------------------------------------------------------------------- */
/* IPHONE X PRO MEDIA QUERIES (PORTRAIT) ------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {

  section#cover { background:#000; display: inline-block; width:100%;
                header h1     { color:$color-white; font: 600 40px $font-main; letter-spacing: 0.02em; margin:0 0 0 0; text-align: center; }
                .caption      { position:absolute; text-align:center; top:30%; width:100%;
                              img { height:auto; width:25%!important; }
                              }
                article figure img { height:100vh; }
                }
}
