 /* SCSS Document */

/* ---------------------------
SCSS RCDE · STAGE FRONT
http://www.rcde.es
Creat per Signia - ABRIL 2021
------------------------------ */

/* Primary colors */
$colorPrimary:#0081CD;
$colorSecondary:#001521;



/* General Styles */
$corporateColor:#0081CD;
$corporateBackground:#0081CD;
$formBackground : darkgrey;
$formHeadBackgroundColor : #323232;
$formHeadColor : white;
$sortingArrowsColor : black;
$footerBackground:black;



/* Fonts */
$paragraph : 300 1em 'ProximaNova', sans-serif;
$projectType : 'dunbarlowbold', sans-serif;

$font-proxima: 'ProximaNova', sans-serif;
$font-proximasemibold: 'ProximaNovaSemibold', sans-serif;
$font-main: 'dunbarlowbold', sans-serif;
$font-medium: 'dunbarmedium', sans-serif;


/* Buttons */
$btnPrimary:#0081CD;
$btnPrimaryHover:#000;
$btnSecondary:#247e5a;



/* Colors */
$color-white: #fff;
$color-cerulean: #247e5a;
$color-cerulean-dark: #003969;
$color-sunflower: #fccf00;
$color-red: #fc4900;
$color-red-alt: #f06363;
$color-palegrey: #f2f5f7;
$color-mediumgrey: #d9dcde;
$color-grey: #99a1a6;
$color-navy: #001521;
$color-black: #000;
$color-green: #57c059;
$color-pitch-green: #4ea156;
$color-pitch-green-dark: #3b9744;



$ease-in: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out: cubic-bezier(0.86, 0, 0.07, 1);
$ease-default: $ease-out;



/* Breakpoints */
$bp-mobile-portrait : 465px;
$bp-mobile-landscape: 640px;
$bp-tablet-portrait:  768px;
$bp-tablet-landscape: 980px;
$bp-desktop:          1280px;
