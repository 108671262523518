
section#stagefrontstadium { background:#fff;
                  ul              { margin: 0; }
                  header h2       { color:$colorSecondary; font: 600 28px $font-medium; margin:0 0 40px 0; text-align: center; }
                  #section-header { background: #00152130; background-size: 100%; background-size: 100%; background-position: center center; height: 100%; overflow: hidden;
                                  figure          { height: 100%; max-height: 640px; overflow: hidden; }
                                  img             { height: 100%; margin-top:-20%; }
                                  header h2       { color:$color-white; }
                                  .grid-container { left: 0; max-width: none; position: absolute; top:20px; width: 100%; }
                                  }
                 aside#intro   { display: inline-block; padding:100px 40px 80px; width:100%;
                               p { color:$colorSecondary; font:400 18px $font-proxima; line-height: 24px; margin:0 0 20px 0; text-align: left; }
                               ul { margin:10px auto 0 auto;
                                  li { margin-bottom: 20px; text-align: center;
                                     img       { display: inherit; margin:0 auto 20px; max-width:35%; }
                                     header h4 { color:$colorSecondary; font:400 16px $font-medium; text-align: center; text-transform: uppercase; }
                                     p          { font-size:16px; line-height: 1.2em; padding:0 10%; text-align: center; }
                                     }
                                  }
                               p.btn    { display: inline-block; margin:30px 0 0 0; padding:0; min-height: inherit!important;
                                        a { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                        }
                               }

                 aside#video   { margin:0 auto; max-width:60rem; padding: 60px 0; text-align: center; }

                 aside#boxes   { background: #f2f5f7; display: inline-block; margin-top: 0; padding:60px;
                               .box   { background: $color-white; box-shadow:0 2px 4px 0 rgba(0,0,0,.04); margin:0 0 40px 0; max-width: none!important; padding:40px;
                                      header h2 { text-align: left; }
                                      div.right { padding:0 0 0 60px; }
                                      p.subtitle{ color:$colorSecondary; font: 600 18px $font-main; margin: 0 0 20px; text-transform: uppercase; }
                                      p         { color:$colorSecondary; font: 400 16px $font-proxima; line-height: 24px; }
                                      p a.btn { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                      p a.btn:hover { background:$color-black; opacity: 1; }
                                      }
                               }
                 aside#history     { background:$colorSecondary; padding:80px 0;
                                   .large-12 { margin:0 0 100px 0; }
                                   header h2 { color: $color-white; font: 42px $font-main!important; margin: 0 0 60px 0; text-align: center; }
                                   p         { color: $color-white!important; font-size:18px!important;  }
                                   .right    { padding:0 0 0 60px; }
                                   }

}








section#stagefront { background:#fff;
                  header h2       { color:$colorSecondary; font: 600 28px $font-medium; margin:0 0 40px 0; text-align: center; }
                  #section-header { background: #00152130; background-size: 100%; background-size: 100%; background-position: center center; height: 100%; overflow: hidden;
                                  figure          { height: 100%; max-height: 640px; overflow: hidden; }
                                  img             { height: 100%; margin-top:-20%; }
                                  header h2       { color:$color-white; }
                                  .grid-container { left: 0; max-width: none; position: absolute; top:20px; width: 100%; }
                                  }
                 aside#intro   { display: inline-block; padding:100px 40px 80px; width:100%;
                               p { color:$colorSecondary; font:400 18px $font-proxima; line-height: 24px; margin:0 0 20px 0; text-align: left; }
                               ul { margin:10px auto 0 auto;
                                  li { margin-bottom: 20px; text-align: center;
                                     img       { display: inherit; margin:0 auto 20px; max-width:25%; }
                                     header h4 { color:$colorSecondary; font:400 16px $font-medium; text-align: center; text-transform: uppercase; }
                                     p          { font-size:16px; line-height: 1.2em; padding:0 10%; text-align: center; }
                                     }
                                  }
                               p.btn    { display: inline-block; margin:30px 0 0 0; padding:0; min-height: inherit!important;
                                        a { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                        }
                               }


                 aside#partners  { background: #f2f5f7; display: inline-block; margin-top: 0; padding:80px 0 120px; width: 100%;
                                 ul { margin:0 auto; max-width: 75%; }
                                 .box      { background: $color-white; box-shadow:0 2px 4px 0 rgba(0,0,0,.04); margin:0 10px 0 10px; max-width: none!important; padding:40px; text-align:center; width:23%!important; }
                                 header h3 { color:$colorSecondary; font: 600 42px $font-main; margin:0 0 30px 0; text-align: center; text-transform: uppercase; }
                                 p         { color:$colorSecondary; font: 400 16px $font-proxima; line-height: 24px; }
                                 }

                 aside#brands  { background:$color-black; display: inline-block; margin-top: 0; padding:80px 0 120px; width: 100%;
                                 ul { margin:0 auto; max-width: 100%; }
                                 .box      { margin:0 10px 0 10px; max-width: none!important; padding:0; text-align:center; width:23%!important; }
                                 header h3 { color:$color-white; font: 600 42px $font-main; margin:0 0 40px 0; text-align: center; text-transform: uppercase; }
                                 header h4 { color:$color-white; font: 600 18px $font-medium; margin:20px 0 20px 0; text-align: center; text-transform: uppercase; }
                                 p         { color:$color-white; font: 400 16px $font-proxima; line-height: 24px; }
                                 }

                 aside#stagefront { padding:80px 60px 120px;
                                  figure    { margin:0 0 40px; text-align: center;
                                            img { max-width: 30%; }
                                            }
                                  header h2 { color:$colorSecondary; font: 600 42px $font-main; margin:0 0 40px 0; text-align: center; }
                                  ul li     { border-left:1.5px solid #00000050; color:$colorSecondary; font: 400 18px $font-proxima; min-height:260px; padding:0 30px; }
                                  }
  }

.btf       {
           article { margin:0; }
           figure#header { background: #000!important;
                         img         { padding-top:169px; width:100%; }
                         }
           #wrappertop { background: #000!important; }
           aside#dark { background:$color-black; padding:120px 0;
                 p { color:$color-white!important; font-size:18px; text-align: center; }
                 header h1 { color:$color-white!important; font: 600 42px $font-main; text-align: center; }
                 }
                 .large-6 { padding:0 30px; }
                 .responsive-embed { padding-bottom:56.1%!important; }
          #episodes { margin-top:30px;
                    header h3 { color:#fff; font: 600 24px $font-main; text-align: center; text-transform: uppercase; }
                    }
         }

figure.backto { margin: 0 0 80px!important;
              img { max-width: inherit!important; width:100%; }
              }
