
section#buyticket { background:#fff;
                  ul              { margin: 0; }
                  header h2       { color:$colorSecondary; font: 600 32px $font-main; text-align: center; }
                  #section-header { background: #001521; background-size: auto; background-size: cover; height: 100vh; margin-top:0;
                                  figure          { height: 100vh; overflow: hidden; position:relative; }
                                  header h2       { color:$color-white; }
                                  .grid-container { left: 0; max-width: none; position: absolute; top: 10px; width: 100%; }
                                  }
                                  .boxes          { margin:-30% 0 0 0; position: relative;
                                                  div { background:#fff; border-radius:20px; margin:0 10px; padding:30px 25px; width:30.9%;
                                                      p { text-align: center; }
                                                      span{ background:#000; border-radius:3px; color:$color-white; display: inline-block; font:600 16px $font-medium; margin:0 auto; min-width:160px; padding:7px 20px; text-align: center; text-transform: uppercase; }
                                                      p a.btn { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                                      p a.btn:hover { background:$color-black; opacity: 1; }
                                                      ul { margin:30px 0 0 0; min-height: 150px;
                                                           li { display: block; color:$color-black; font: 400 16px $font-proxima; margin:0; padding: 4px 0; text-align: left;
                                                              i.fas { margin: 0 7px 0 0 ; }
                                                              }
                                                         }
                                                      .icons  { display: inline-block; margin:20px 0; width: 100%;
                                                              li  { text-align: center; width:32px; }
                                                              img { max-width:32px; }
                                                              }
                                                      }
                                                       #big  { margin-top:-60px;
                                                             ul { min-height:260px; }
                                                             }
                                                  }
                }
 
