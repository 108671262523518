


section#forms {
              .center     { text-align: center; }
              header h1   { color:$colorPrimary; font:600 40px 'acrombold', sans-serif; letter-spacing: -0.08em; margin:20px 0 0 0; text-align: center; }
              header h3   { color:$colorPrimary; font:600 20px 'acrombold', sans-serif; letter-spacing: -0.08em; margin:20px 0 0 0; text-align: left; }
              p           { }
              p strong    { font:600 16px 'acrombold', sans-serif; }
              div.columns { padding-left: 0.9375rem; padding-right: 0.9375rem; }
              ul          { list-style-type: none; margin-left: 0; }
              label       { font:600 14px 'acrombold', sans-serif; margin:0 0 10px 0; }
              article     { padding:80px 0; }
              button.btn  { background:#247e5a; border-radius:4px; color:#fff; font:600 16px 'acrommedium', sans-serif; letter-spacing: -0.04em; text-align: center; text-transform: uppercase;
                          &:hover { background: #343434; }
                          }
              article #reset { margin:0 auto; max-width:65rem; }
              }
