@charset "utf-8";
/* CSS Document - CSS per a dispositius mòbils */

/* -----------------------------------
CSS RCD ESPANYOL - STAGE FRONT STADIUM
https://www.rcdespanyol.com/
Creat per Signia - Juny 2023
-------------------------------------- */

/* --------------------------------------------------------------------------------------------------- */
/* IPHONE X PRO MEDIA QUERIES (PORTRAIT) ------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {

.btf .large-6 { margin:0 0 20px; padding:0 0; }
.sticky.is-anchored { position: initial; }
#wrappertop         { background:#000!important; height:90px; }
#topbar             { height: inherit!important; position: fixed; top: 0; z-index: -1;
                    #navigation { min-height:inherit; padding:0; }

                    #language_selector          { left:-10px!important; padding-top:0; top:-10px; /* position:absolute;  */
                                                nav a      { height:40px; }
                                                nav li > a { padding: 0px 15px 0; }
                                                nav li > a:after { right: -2px; top: 30px; }
                                                nav ul ul { right: inherit; top:55px; }
                                                .dropdown li.is-dropdown-submenu-parent > a { padding:23px 25px 23px 10px; }
                                                }
                  .off-canvas-content .button { position:absolute; right:10px; top:40px; }
                  .off-canvas-content .button i { font-size:1.5em; }
                  .drilldown                  { min-height:400px; }
                  .drilldown a                { font-size:14px; padding:0.5rem 0; }
                  .drilldown .js-drilldown-back a { font-size:0; padding:10px 20px; }
                  }

#navs             { margin-top:45px;
                  #logos {
                         li { padding:0!important; text-align: center!important; }
                         li img { margin:0; max-width: 165px; }
                         }
                  }





/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* SECTION HOMEPAGE (INICIO) */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

section#homepage {
                 header h1 { font-size: 38px; line-height: 1.2em; }
                 article {
                         .grid-x { padding:0;  }
                         }
                 #videohome { height: 26vh; margin:90px 0 0 0; }
                 aside#intro{ padding:40px;
                            p.btn { margin:0 0 20px 0; }
                            ul li { padding:0;
                                  img { max-width: 70%; }
                                  h3  { font-size:14px; }
                                  }
                            }
                 aside#central { margin:0; padding: 40px 40px 60px 40px;
                               h2    { font-size: 38px!important; line-height: 1.2em!important; margin:0 0 30px 0; }
                               ul    { padding:0 0 0 0;
                                     li { margin-bottom:60px; padding:0; }
                                     li:last-child { margin-bottom:0; }
                                     }
                               }
                 aside#events  { margin:0; padding: 40px;
                               h2             { line-height: 1.2em!important; margin:0 0 30px 0!important; }
                               .events_slider { padding:20px;
                                              img      { margin-bottom:20px; }
                                              h4       { font-size:24px; line-height: 1em; margin:0 0 10px; }
                                              .subtitle{ font-size:16px; margin:0; }
                                              p.date   { font-size:12px; letter-spacing: normal; margin:0 0 20px 0; }
                                              p.btn a  { display: inline-block; width:100%; }
                                              }
                               }
                 aside#stagefront { padding:40px;
                                  figure img { max-width: inherit; width:100%; }
                                  p.subtitle { max-width: inherit; }
                                  }
                 }





/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* SECTION BUYTICKET (ENTRADAS) */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
 section#buyticket  {
                    #section-header { height:100%;
                                    figure    { height:100%; }
                                    header h2 { font-size:20px!important; }
                                    }
                    .grid-container { margin:10px 0 0 0; padding:0 10px; }
                    .boxes          { margin:0; padding:40px 40px 0 40px;
                                    div  { background:#f2f5f7; margin:0 0 40px 0; width:100%; }
                                    #big { margin-top:0!important;
                                         ul { margin-bottom:40px; min-height:inherit; }
                                         }
                                    }
                    article #upselling { padding:40px!important; }
                    aside#video        { padding:40px 40px 20px 40px!important; }

                    }





/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* SECTION EXPERIENCE (EXPERIENCIAS) */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
section#experience   {
                     header h1 { font-size: 38px; line-height: 1.2em; }
                     article   {
                               .grid-x { padding:0; }
                               }
                     #section-header {
                                     img { margin:90px 0 0 0; }
                                     .grid-container { top:-200px; }
                                     header h2 { font-size:20px!important; }
                                     }
                     aside#tabs ul   { margin: 0 0 10px;
                                     li { margin:0 0 10px 0; }
                                     }
                     aside#boxes     { padding:40px; width:100%;
                                     .box { padding:20px;
                                          img { margin:0 0 20px 0; }
                                          div.right { padding:0; }
                                          }
                                     ul.icons li { padding:0;
                                                 img { margin:0 0 10px; }
                                                 }
                                     ul.icons li:last-child { float: left; }
                                     }
                     }





/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* SECTION EVENTS (EVENTOS) */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
section#events   {
                 header h1 { font-size: 38px; line-height: 1.2em; }
                 article   {
                           .grid-x { padding:0; }
                           }
                 #section-header img { margin:90px 0 0 0; }
                 aside#boxes         { padding:40px 40px 0 40px;
                                     header h1 { font-size:38px; }
                                     h2.center { font-size:20px!important; }
                                     .box      { margin:0 0 40px 0; padding: 20px; width:100%; }
                                     }
                 aside#historic       { padding:40px 40px 0 40px;
                                     header h2 { font-size:32px; margin:0 0 30px; }
                                     .box      { margin:0 0 40px 0; padding: 20px; width:100%; }
                                     .row      { margin:0; padding: 0!important; }
                                     }
                 }

section#organiza {
                 header h1 { font-size: 38px; line-height: 1.2em; }
                 article   {
                           .grid-x { padding:0; }
                           }
                 #section-header img { margin:90px 0 0 0; }
                 aside#boxes         { padding:40px 40px 0 40px;
                                     header h1 { font-size:38px; }
                                     h2.center { font-size:20px!important; margin:0 0 20px 0; }
                                     .box      { margin:0 0 40px 0; padding: 20px; width:100%; }
                                     .horizontal { margin:0 0 40px 0; padding:0;
                                                 li {
                                                     .responsive-embed { margin-bottom:0; padding-bottom: 56%; }
                                                    }
                                                 header h4  { margin:40px 0 0 0; }
                                                 #partidos,
                                                 #conciertos { margin-bottom: 0 !important; }
                                                 .show-for-small-only { padding-bottom: 0!important; }
                                                 }
                                     }
                 aside#espacios     { padding:0 30px;
                                    ul li { margin:0 0 40px 0; }
                                    }

                 }





/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* SECTION STAGE FRONT STADIUM */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
section#stagefrontstadium  {
                           header h1 { font-size: 38px; line-height: 1.2em; }
                           article   {
                                     .grid-x { padding:0; }
                                     }
                           #section-header {
                                           img { margin:90px 0 0 0; }
                                           .grid-container { top:-200px; }
                                           header h2 { font-size:20px!important; }
                                           }
                           aside#intro{ padding:40px;
                                      p.btn { margin:0 0 20px 0; }
                                      ul li { padding:0;
                                            p   { padding: 0; }
                                            img { max-width: 50%; }
                                            h3  { font-size:14px; }
                                            }
                                      }
                           aside#history { padding: 40px;
                                         .row .large-12 { margin:0!important; padding:0!important; }
                                         header h2      { font-size:38px!important;line-height: 1.2em!important; margin:0 0 30px 0; }
                                         .small-12      { padding:0;
                                                        img { margin:0 0 20px 0; }
                                                        }
                                         #mobileimg     { margin-top:40px; }
                                         }
                          }

section#stagefront  {
                     header h1 { font-size: 38px; line-height: 1.2em; }
                     article   {
                               .grid-x { padding:0; }
                               }
                     #section-header {
                                     img { margin:90px 0 0 0; }
                                     .grid-container { top:-180px; }
                                     header h2 { font-size:20px!important; }
                                     }
                     aside#stagefront{ padding:40px;
                                     p   { padding: 0; }
                                     header h2 { font-size:32px; }
                                     figure {
                                            img { max-width: inherit; width:100%; }
                                            }
                                     ul     { margin:0; }
                                     ul li  { min-height: inherit; padding-bottom: 60px; }
                                     }
                     aside#partners { padding: 40px;
                                    .row           { margin:0!important; padding:0!important; }
                                    header h2      { font-size:38px!important;line-height: 1.2em!important; margin:0 0 30px 0; }
                                    ul             { max-width: none; padding:0;
                                                   li:last-child { float:left; }
                                                   }
                                    .box           { margin:3px; width:48%!important; }
                                    }
                     aside#brands   { padding: 40px;
                                    .row           { margin:0!important; padding:0!important; }
                                    header h2      { font-size:38px!important;line-height: 1.2em!important; margin:0 0 30px 0; }
                                    ul             { margin:0; padding:0; }
                                    .box           { margin:0 0 60px; width:100%!important; }
                                    }

                          }





/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* SECTION LEGAL */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
section#legal   {
                #section-header { height: 220px;
                                .grid-container { margin:0; padding:40px; }
                                }
                article         { max-width: 100%; padding:40px; }
                .row article    { padding:0; }
                header h2       { font-size:24px; }
                header h3       { font-size:18px; }
                }





/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* FOOTER */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
footer    { padding:40px;
          article{ display: block; padding:0!important; }
          figure { text-align:center;
                 img { max-width: 30%; }
                 }
          #contacto { margin:0; width:100%;
                    figure img { max-width:75%; }
                    ul li { padding:0; text-align: center; }
                    ul li:nth-child(4),
                    ul li:nth-child(5) { display: none; }
                    }
          #legal            { margin:0 auto!important; padding:0!important; text-align: center;
                            a        { font-size:11px; padding: 0!important; }
                            .columns { margin:0; padding: 0 8px!important;}
                            }
          #copyright        { margin-top:10px; padding-top:10px;
                            .grid-x  { padding:0; text-align: center; }
                            p { margin:20px 0 0 0!important; padding: 0!important; text-align: center; }
                            }
          .social           { margin-top:20px; text-align: center;
                            .cell { padding:0 5px; }
                            }
          #links a          { text-align: center; }

          aside#stagefront  { padding:10px 0;
                            header h3 { text-align: center; }
                            p         { text-align: center; }
                            figure    { margin:0;
                                      img { margin:0 0 10px 0; max-width:200px; }
                                      }
                            .large-11 { padding:0; }
                            }
          }




/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* GENERAL STYLES */
/* --------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

header h1,
header h2,
header h3,
header h4,
header h5,
header h6           { line-height: 1.2em!important; }

#section-header     { margin-top:0!important;
                    header h1 { font-size:28px; line-height: 32px; margin-top:80px!important; }
                    img       { height: 100%; }
                    }

#boxes              { margin: 40px 0;
                    div.cell { margin:0; width: 100%; }
                    ul li { margin-left: 0 ; margin-right:0; padding: 30px 30px;
                          figure { margin:0 0 20px 0; text-align: center; width: inherit; }
                          }
                    }

aside#como-llegar {
                  header h2  { line-height: 1.2em!important; margin:0 0 20px 0!important; }
                  p.subtitle { max-width: inherit; }
                  figure img { margin:0; }
                  .box { margin: 0; padding:40px!important;
                       img { margin: 0 5px 0 0; }
                       ul { display: inline-block; margin:0 0 20px 0; padding: 20px 0; width: 100%;
                          li { display: inline; font-size:12px; margin:0 0 10px; }
                          }
                       #transportes li { margin:0 5px 5px 0!important; width:48%;
                                       a { display: inline-block; padding:10px; }
                                       .transport { font-size:10px; margin:0; }
                                       span       { font-size:10px; margin:0; }
                                       }
                       .tram           {
                                       a    { padding:10px 10px!important; }
                                       img  { max-width:19px; }
                                       }
                       .renfe          {
                                       a    { padding:11px 10px!important; }
                                       img  { max-width:19px; margin:0 10px 0 0; }
                                       }
                       .fgc            {
                                       a    { padding:11px 10px!important; }
                                       img  { max-width:16px; }
                                       span { letter-spacing: -0.04em; }
                                       }
                       }
                  .grid-x .columns { padding:0; }
                  .responsive-embed { margin:20px 0; }
                  }

aside#upselling   { padding: 0!important;
                  .row      { padding:0; }
                  #boxes    { margin-bottom:0!important; padding-bottom: 0!important; }
                  #features { margin:20px 0 0!important; padding:0;
                            div { margin:0 0 40px 0!important; padding:30px!important; width:100%!important;
                                ul { margin-bottom:30px!important; min-height:none!important; }
                                }
                            .highlited { margin:0!important; }
                            }
                  }


aside#video       { padding:0 40px 20px!important; max-width: 100%;
                  .responsive-embed { padding-bottom: 56%; }
                  }



a.btn              { display:inline-block; width:100%; }
p.btn a            { display:inline-block; width:100%; }

.slick-prev::before,
.slick-next::before { font-size:32px!important; }
.slick-prev         { left: -15px!important; }
.slick-next         { right: -5px!important; }

}

/* IPHONE 6 */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
#kaltura_player_302225913 { height: 24.10vh!important; max-width: 25.9rem; margin-top:139px; }
#videohome                { margin-top:142px; max-width:none!important; padding: 0 !important; }
}




.navmobile             { color: #fff; cursor: pointer; height: 42px; /* left: 20px; */ padding: 10px 0 0 0; position: absolute; text-align: center; width:42px; }
