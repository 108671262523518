
section#experience { background:#fff;
                   ul              { margin: 0; }
                   header h2       { color:$colorSecondary; font: 600 32px $font-main; text-align: center; }
                   #section-header { background: #00152130; background-size: 100%; background-size: 100%; background-position: center center; height: 100%; overflow: hidden;
                                   figure          { height: 100%; max-height: 640px; overflow: hidden; }
                                   img             { height: 100%; margin-top:-20%; }
                                   header h2       { color:$color-white; }
                                   .grid-container { left: 0; max-width: none; position: absolute; top:20px; width: 100%; }
                                   }
                  aside#video   { margin:0 auto; max-width:60rem; padding: 60px 0; text-align: center; }
                  aside#tabs    { margin:0 auto; max-width:100rem; padding: 20px 0 0; text-align: center; width:100%;
                                ul         { margin:20px 0 40px; }
                                li a       { background: $color-black; border:1.8px solid $color-black; border-radius:30px; color: $color-white; display: inline-block; font: 600 16px $font-main; padding:10px 30px; text-align: center; text-transform: uppercase; width:100%; }
                                li a:hover { background: $color-white; border:1.8px solid $color-black; color: $color-black!important; opacity:1; }
                                }
                 aside#boxes   { background: #f2f5f7; display: inline-block; margin-top: 0; padding:60px;
                               .box   { background: $color-white; box-shadow:0 2px 4px 0 rgba(0,0,0,.04); margin:0 0 40px 0; max-width: none!important; padding:40px;
                                      header h2 { text-align: left; }
                                      div.right { padding:0 0 0 60px; }
                                      p.subtitle{ color:$colorSecondary; font: 600 18px $font-main; margin: 0 0 20px; text-transform: uppercase; }
                                      p         { color:$colorSecondary; font: 400 16px $font-proxima; line-height: 24px; }
                                      p a.btn { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                      p a.btn:hover { background:$color-black; opacity: 1; }
                                      }
                               }
                               aside#upselling   { background:#F2F5F7; display: inline-block; padding:60px 40px 40px; width:100%;
                                             p   { color:$colorSecondary; font:400 18px $font-proxima; margin:0 0 20px 0; text-align: center; }
                                             #features { margin:50px auto 80px auto;
                                                       div { background: $color-white; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); border-radius: 30px; margin:0 10px; min-height: 420px; padding:40px; width:30%;
                                                           ul { margin-bottom:10px;
                                                                 li { font:400 18px $font-proxima; padding:5px 0;
                                                                    strong { color:$colorSecondary; display: inline-block; font:600 18px $font-medium; margin-bottom:10px; }
                                                                    i.fas  { float:left; margin: 4px 5px 0 0; font-size:12px; }
                                                                    }
                                                           }
                                                       }
                                                       div.highlited { background: $color-black; color: $color-white;
                                                                     p { color: $color-white; }
                                                                     ul li { color: $color-white;
                                                                           strong { color: $color-white; }
                                                                           i { color: $color-white; }
                                                                           }
                                                                     }
                                                       header h3 { font: 600 28px $font-main; line-height: 32px; text-transform: uppercase; }
                                                       p { text-align: left; }
                                                       }
                                             ul  { margin:50px auto 80px auto; max-width: 75rem;
                                                 li {
                                                   img       { display: inherit; margin:0 auto 10px; max-width: 75%; }
                                                   header h3 { color:$colorSecondary; font:400 16px $font-medium; text-align: center; text-transform: uppercase; }
                                                   }
                                                }
                                             p.btn    { display: inline-block; margin:50px 0 0 0; padding:0; min-height: inherit!important; text-align: center;
                                                      a { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                                      }
                                             }


                               }
