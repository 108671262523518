
section#contact { background:#fff;
                  ul              { margin: 0; }
                  header h2       { color:$colorSecondary; font: 600 32px $font-main; line-height: 1em; text-align: center; }
                  #section-header { background: #00152130; background-size: 100%; background-size: 100%; background-position: center center; height: 100%; overflow: hidden;
                                  figure          { height: 100%; max-height: 640px; overflow: hidden; }
                                  img             { height: 100%; margin-top:-20%; }
                                  header h2       { color:$color-white; }
                                  .grid-container { left: 0; max-width: none; position: absolute; top:20px; width: 100%; }
                                  }

                  aside#boxes   { background: #f2f5f7; display: inline-block; margin-top: 0; padding:60px;
                               .box   { background: $color-white; box-shadow:0 2px 4px 0 rgba(0,0,0,.04); margin:0 0 40px 0; max-width: none!important; padding:40px;
                                      header h2 { text-align: left; }
                                      div.right { padding:0 0 0 60px; }
                                      p.subtitle{ color:$colorSecondary; font: 600 18px $font-main; margin: 0 0 20px; text-transform: uppercase; }
                                      p         { color:$colorSecondary; font: 400 16px $font-proxima; line-height: 24px; }
                                      p a.btn { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                      p a.btn:hover { background:$color-black; opacity: 1; }
                                      }
                               }
                 ul li         { color:$colorSecondary; font: 400 16px $font-proxima; margin:0; padding:7px 0;
                               a { border-color:$colorSecondary; color:$colorSecondary!important; padding:0;  }
                               }
                #form-info     {
                               header h2 { margin:0 0 20px 0; text-align: left; }
                               }
                }
