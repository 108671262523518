
section#events    { background:#fff;
                  ul              { margin: 0; }
                  header h2       { color:$colorSecondary; font: 600 32px $font-main; text-align: center; }
                  #section-header { background: #00152130; background-size: 100%; background-size: 100%; background-position: center center; height: 100%; overflow: hidden;
                                  figure          { height: 100%; max-height: 640px; overflow: hidden; }
                                  img             { height: 100%; margin-top:-10%; }
                                  header h2       { color:$color-white; }
                                  .grid-container { left: 0; max-width: none; position: absolute; top: 20px; width: 100%; }
                                  }

                  aside#boxes  { background: #f2f5f7; display: inline-block; margin-top: 0; padding:60px;
                               header h1          { color:$colorSecondary; font: 600 42px $font-main; text-align: center; }
                               header h2.center   { color:$colorSecondary; font: 400 28px $font-medium; margin:0 0 30px 0; }
                               .box   { background: $color-white; box-shadow:0 2px 4px 0 rgba(0,0,0,.04); margin:0 15px 40px 15px; max-width: none!important; padding:40px; width:31.1%;
                                      header h2   { font-size: 28px; margin: 0; text-align: left; }
                                      img         { margin:0 0 25px 0; }
                                      p.subtitle  { color:$colorSecondary; font: 600 18px $font-main; margin: 0 0 5px; min-height: inherit; text-transform: uppercase; }
                                      p           { color:$colorSecondary; font: 400 16px $font-proxima; line-height: 24px; min-height:160px; }
                                      p.date      { color:$colorSecondary; font: 400 16px $font-medium; margin: 0 0 20px; min-height: inherit; opacity: .4; }
                                      a.btn       { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                      a.btn:hover { background:$color-black; opacity: 1; }
                                      p.btn       { margin:0; min-height: inherit; padding:0; text-align: left; }
                                      }
                               }
                   aside#historic  { background: #f2f5f7; border-top:2px solid $color-white; display: inline-block; margin-top: 0; padding:60px; width: 100%;
                                   header h2          { color:$colorSecondary; font: 600 38px $font-main; margin:0 12% 40px; text-align: center; }
                                   .box   { background: $color-white; box-shadow:0 2px 4px 0 rgba(0,0,0,.04); margin:0 15px 40px 15px; max-width: none!important; padding:40px; width:31.1%;
                                           header h2   { font-size: 22px; margin: 0; text-align: left; }
                                           img         { margin:0 0 25px 0; }
                                           p.subtitle  { color:$colorSecondary; font: 600 18px $font-main; margin: 0 0 5px; min-height: inherit; text-transform: uppercase; }
                                           p           { color:$colorSecondary; font: 400 16px $font-proxima; line-height: 24px; min-height:160px; }
                                           p.date      { color:$colorSecondary; font: 400 16px $font-medium; margin: 0 0 20px; min-height: inherit; opacity: .4; }
                                           a.btn       { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                                           a.btn:hover { background:$color-black; opacity: 1; }
                                           p.btn       { margin:0; min-height: inherit; padding:0; text-align: left; }
                                           }
                                  }
                    }





section#organiza  { background:#fff;
                  ul              { margin: 0; }
                  header h2       { color:$colorSecondary; font: 600 32px $font-main; margin:0 0 40px 0; text-align: center; }
                  #section-header { background: #00152130; background-size: 100%; background-size: 100%; background-position: center center; height: 100%; overflow: hidden;
                                  figure          { height: 100%; max-height: 640px; overflow: hidden; }
                                  img             { height: 100%; margin-top:-10%; }
                                  header h2       { color:$color-white; }
                                  .grid-container { left: 0; max-width: none; position: absolute; top: 20px; width: 100%; }
                                  }

                  aside#boxes     { background: #f2f5f7; display: inline-block; margin-top: 0; padding:60px;
                                  header h1          { color:$colorSecondary; font: 600 42px $font-main; text-align: center; }
                                  .horizontal        { background: $color-white; box-shadow:0 2px 4px 0 rgba(0,0,0,.04); margin:0 15px 40px 15px; max-width: none!important; padding:40px; width:100%;
                                                      header h4   { color:$colorSecondary; font: 26px $font-main; line-height: 1em; margin: 0 0 40px 0; text-align: center; }
                                                      header h5   { color:$colorSecondary; font: 26px $font-main; margin: 0 0 20px 0; text-align: left; }
                                                      p           { color:$colorSecondary; font: 400 16px $font-proxima; line-height: 24px; padding-right:10%; }
                                                      p.btn       { margin:20px 0; padding:0; text-align: left; }
                                                      ul          { display: inline-block; }
                                                      #conciertos,
                                                      #partidos   { margin-bottom:60px!important; width: 100%; }
                                                      }
                                  }
                  aside#espacios { padding:60px 0;
                                 header h3   { color:$colorSecondary; font: 22px $font-main; margin: 0 0 20px 0; text-align: center; text-transform: uppercase; }
                                 p           { font-size:16px; margin:20px 0;  }
                  }
                    }
