/* Layout */
html            { -moz-osx-font-smoothing:grayscale; -webkit-font-smoothing:antialiased; line-height: 28px; scroll-behavior: smooth; }
body            { background:$color-palegrey; font:400 16px $font-proxima; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.row            { max-width:80rem; }
.grid-container { max-width:80rem; }
figure          { margin:0; }
.center         { text-align:center; width:100%; }
a               { color:#fff!important; }
a:hover         { opacity:0.5; }
:target:before  { content: ""; display: block; height: 100px; margin: -100px 0 0; }
.row            { max-width: 90rem!important; }
.left           { float:left; }
.right          { float:right; }
ul              { list-style-type: none; margin: 0; }
ul li           { list-style-type: none; }
p               { color: $color-black!important; font:400 16px $font-proxima; line-height: 22px; text-align: left; }
p a             { color: $color-black; }
#navs           { position: relative; z-index: 9999999; }
#topbar         { display:block; margin:0 auto; padding:10px auto; position: relative; width:100%; z-index: 9999999;
                figure#logo            { margin:27px 0 0 50px; width: 260px; }
                [role="menu"]          { border-radius: 0; display: block; margin-bottom: auto; margin-top: auto; width: 100%; }
                [role="complementary"] { display: inline-block; float: left; font-size:12px; line-height: 16px; margin-right: 10px; text-align: center; text-transform: uppercase; width: 30px; }
                [role="region"]        { display: block; font-size: 10px; opacity: .6; text-transform: uppercase }
                [role="img"]           { margin-left:auto; opacity: 1; transition: transform .4s cubic-bezier(.23,1,.32,1); }
                [aria-expanded="true"] [role="img"] { transform: rotate(45deg); }
                span [role="main"]     { font-size: 12px; font-weight: 600; text-transform: uppercase; }
                .day                   { display: block; font-size: 17px; }


                 div.social             { border-left:1px solid rgba(255,255,255,.2); border-right:1px solid rgba(255,255,255,.2); display: flex; margin:0; padding:28px 15px;
                                       a       { display: block; margin:0 5px; text-align: center; }
                                       a:hover { color:$color-sunflower; opacity:1; }
                                       }

                #language_selector     { height: 32px; padding-top:18px; position: relative; width:auto;
                                       .opens-right img { min-width:18px; }
                                       .dropdown { border:none;
                                       li.is-dropdown-submenu-parent > a { font-size:12px; margin:0; padding:29px 25px 29px 10px; text-transform: uppercase; }
                                       li.is-dropdown-submenu-parent > a:hover,
                                       li.is-dropdown-submenu-parent > a:focus { color:$color-white; opacity:1; }
                                       li.is-dropdown-submenu-parent > a::after { border-color: #fff transparent transparent;}
                                               ul.menu { border:none; max-width:200px; padding:5px 0 0 0; z-index:9999;
                                                       li { display: inline-block; }
                                                         a   { color:$color-black; font-size: 13px; font-weight: 600; padding:10px 10px!important; text-transform: uppercase; }
                                                         img { margin-right:8px; }
                                                         }
                                                 }
                                      }
                }

.box           { margin: 0 auto;max-width: 37em; text-align: center; }

figure#emblem  { margin:10px 0 0 10px;
                img { min-width:55px; }
               }


footer      {
            .twitter img { margin-top:-4px; width:16px; }
            background:#001521; color:hsla(0,0%,100%,.5); padding:60px 0 20px;
            nav a { color:$color-white; display:block; font:13px $font-proximasemibold; line-height:36px; padding:0; text-transform: uppercase;
                  &:hover { color:$color-sunflower; opacity:1; }
                  }
            ul    { margin:0; }
            ul li { color: $color-white; opacity:0.5; }
            h4    { color: $color-white; font:400 13px $font-proxima; opacity:0.5; }

            #copyright { border-top:1px solid hsla(0,0%,100%,.2); padding-top:30px;
                        ul li   { opacity:1; }
                        ul li a { color:$color-white; opacity:1; }
                        #social ul    { margin-top:5px; width:auto; }
                        #social ul li { padding:0 10px; width:auto; }
                        p             { color: $color-white!important; display: inline-block; font-size:14px; margin:15px 0 15px 25px!important; opacity:0.5; width:100%; }
                       }
            article    { display:flex; padding-bottom:0; }

            #contacto  { margin:0 30px 0 0;
                        figure { margin-bottom: 20px; }
                        ul li  { padding:5px 0; }
                        a      { text-decoration: underline; }
                       }
            #links a       { color:$color-sunflower; }
            #links a:hover { color:$color-white; }
            .social    { text-align:right;
                      .cell { text-align: center; padding:0 10px; width: auto; }
                       div.grid-x { text-align:right; }
                       a:hover    { color:$color-sunflower; opacity:1; }
                       }
            #legal div { width: auto;
                       .cell {  }
                       a:hover { color:$color-sunflower; opacity: 1; }
                       }
            .btnPrimary { min-width: 140px; text-align: center; }
            aside#stagefront { padding:60px 0 30px; width:100%;
                             figure    { margin:0;
                                       img  { margin-top: -20px; max-width: 120px; }
                                       }
                             .large-11  { padding:20px 5px 0 15px; }
                             header h3 { color:$color-white; font:400 20px $font-main; margin-top:-20px; text-transform: uppercase; text-align: left; }
                             p         { color:$color-white!important; margin:0; text-align: left; }
                             p a       { border-bottom:1px solid $color-white; color:$color-white; text-align: left; }
                             }
            }


aside#sponsors         { display:inline-block; background:$color-white; border-top: 1px solid rgba(217,220,222,.4); padding: 100px 0; text-align: center; width: 100%;
                       p      { margin:0; text-align: center; }
                       p a    { height: 174px; width: 225px; }
                       p img  { height: 174px; width: 225px; object-fit: contain; }
                       ul li  { display: inline; padding:0 2px; }
                       a      { display:inline-block; height: 105px; width: 135px;  }
                       img    { height: 100%; object-fit:contain; width: 100%; }
                       h5     { margin:0; opacity:0.4; text-align: center; }
                       }




/* Fonts */
a            { color: $color-black; }
h1           { color: $colorSecondary; font:48px $font-main; line-height:48px; text-transform: uppercase; }
h2           { color: $colorSecondary; font:44px $font-main; line-height:1; text-align: center; text-transform: uppercase; }
h3           { }
h4           { color: $colorSecondary; font:13px $font-proxima; text-transform: uppercase; }
h5           { color: $colorSecondary; font:12px $font-proximasemibold; text-transform: uppercase; width:100%; }
p            { color: $colorSecondary; font:400 16px $font-proxima; line-height: 1.5; margin: 20px 0; text-align: left; width:100%; }
p:first-child{ margin-top: 0; }
ul li        { color: $colorSecondary; font:400 14px $font-proxima; }


/* General */
ul                   { list-style-type:none; margin:0; }

ul.imagebox          { margin-bottom:60px; margin-top:55px; justify-content: space-between;
                     li img       { left: 0; position: relative; top: 0; width: 100%; }
                     li div.main  { bottom: 50px; left: 50px; position: absolute; }
                     li           { position:relative; }
                     li h2        { color: $color-sunflower; font-size:32px; line-height: 1; margin: 0 0 5px; /* max-width: 4em; */ }
                     li p         { color: $color-white; font: 600 12px $font-proximasemibold; margin: 0; text-transform: uppercase; }
                     a            { border-bottom:none!important; color:$color-white!important; line-height: normal!important;
                                  &:hover { opacity:1; }
                                  }
                   }





/* Botones */
.btnPrimary   { align-items: center; background:#0081CD; border: 1px solid transparent; border-radius: 2em; color:$colorPrimary!important; display:inline-block!important; font:600 13px "ProximaNovaSemibold", sans-serif; justify-content: center; line-height: normal; margin-bottom: 15px; padding:1.33em 1.66em 1.2em; text-transform: uppercase; vertical-align: top; white-space: nowrap;
              img { height: 1.4em; margin-right: .5em; object-fit: contain; }
              }
.btnSecondary { border:1px solid $color-sunflower; border-radius: 2em; color:$colorPrimary!important; font:600 13px "ProximaNovaSemibold", sans-serif; padding:1.33em 1.66em 1.2em; text-transform: uppercase; vertical-align: middle; white-space: nowrap; }
.btnSecondary:hover { background:$color-sunflower!important; opacity:1; }
.more         { border-bottom: 2px solid $color-sunflower; color: $colorPrimary; display: block; font: 600 12px $font-proximasemibold; height: auto!important; line-height: 1.2em; margin: 20px; padding:0; text-transform: uppercase; width:auto!important; }
.btnback      { background:$color-white; border-radius: 1.5em; color: $colorPrimary; font-size: 13px; font-weight: 500; left: 20%; line-height: 1; padding: .85em 1.25em .75em; position: absolute; text-transform: uppercase; top: 185px; z-index:99;
              img     { float:left; transform: rotate(90deg); height: 14px; margin: -1px 3px 0 -4px; vertical-align: middle; width: 14px; }
              &:hover { background:$color-sunflower; color:$color-white; opacity:1;
                      img { filter: invert(100%); }
                      }
              }


/* Tabs */
.tabs         { background:$color-white; border:none; /* margin-top:-5px; */ text-align:center; }
.tabs-content { background:none; border:none; }
.tabs-title   { display: inline-block; float:none; text-align: center; text-transform: uppercase;
              a       { color:$color-black; font-size: 13px; font-weight: 600; opacity: .4; padding:2em 3em; }
              a:hover { opacity:1; }
              }
.tabs-title > a[aria-selected='true'] { background: none; border-bottom:3px solid $color-sunflower; color:$color-black; opacity:1; }
.tabs-title > a[aria-selected='true']::after { background: $color-sunflower; bottom: 0; content: ""; display: block; height: 3px; opacity: 0; position: absolute; transform: translateX(50%) scaleX(0); left: -50%; width: 100%; transition: opacity .5s cubic-bezier(.23,1,.32,1),transform .5s cubic-bezier(.23,1,.32,1); }


/* Pagination */
.pagination          { padding: 40px 0; text-align: center;
                     a        { border:1px solid $color-sunflower; border-radius: 27px; color: $colorPrimary; font-weight: 600; height: 52px; line-height: 52px; text-align: center; padding:inherit; text-transform: uppercase; width:52px;
                     a:hover  { background:$color-sunflower!important; color: $colorSecondary!important; }
                     }
                     .current { background: $color-sunflower; border-radius: 27px; color: $colorPrimary; font-weight: 600; height: 52px; line-height: 52px; text-align: center; padding:0; text-transform: uppercase; width:52px; }
                     }
.pagination-previous { border-radius: 27px; height: 52px; pointer-events: none; vertical-align: middle; width: 52px;
                     img { transform: rotate(180deg); }
                     .disabled         { border-radius: 27px; opacity: .25; }
                     .disabled::before { display: none; }
                     a::before         { display: none; }
                     }
.pagination-next     { line-height: 48px!important;
                     a::after { display: none; }
                     }



/* GALLERY */
.gallery           { background:$color-navy; padding: 60px 0 120px; overflow-x: hidden;
                   p { color:$color-white; margin:0; text-align:center; }
                   }


/* FORM */
#form              { background: $color-white; padding:100px 0; }

div#form-info { background: $color-white; padding:100px 0;
              a          { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
              header h2  { max-width: 400px; }
              p          { }
              p.short    { max-width: 400px; }
              ul li      { margin-top:1.8em; position: relative;
                         span { bottom:25px; height: 16px; position: absolute; right: 10px; width: 16px; }
                         }
              input      { box-shadow: none; border-bottom:1px solid rgba(0,21,33,.15)!important; border:none; box-sizing: border-box; font-size:18px; padding:.75em 0; }
              input:focus{ border-bottom:1px solid rgba(0,21,33,1)!important; box-shadow: none; }
              textarea   { border-bottom:1px solid rgba(0,21,33,.15)!important; border-top:none; border-left:none; border-right:none; box-shadow: none; font-size:18px; padding-left:0; }
              select     { background:none; border-left:none; border-right:none; border-top:none; font-size:18px; padding-left:0; }
              p.legal label label { float:right; width:95%; }
              label > [type="checkbox"] { margin-bottom:0; }
              .btnPrimary{ min-width: 120px; }
              }

#nextmatch    { background: $color-white; margin:0 auto; padding: 40px; text-align: center;
              span    { color: #001521; display: block; font-size: 12px; font-weight: 600px; margin: 0 0 8px; opacity: .4; text-transform: uppercase; }
              p       { text-align: center;
                      a { border:none!important; color:$color-navy; }
                      a strong { font:46px $font-main; text-transform: uppercase; }
                      span     { font-weight: 400!important; }
                      }
              }

.narrow       { margin:100px auto; max-width: 768px;
              header h2   { margin-bottom: 30px; }
              p+header h2 { margin:50px 0 20px; }
              }

.login        { border-top:1px solid #d9dcde; margin-bottom: 100px; padding-top: 100px;
              p { display: inline-block; margin-bottom:1em; margin-top:1em; text-align: center; width:100%; }
            }


#common       { article   { margin:80px 0; }
              header h2 { margin-bottom:40px; }
              }
              #xxss        { padding:20px 0;
                           header h1 { font-size:64px; margin-bottom:50px; }
                           ul li { margin:0; padding:0; }
                           figure    { transition:box-shadow .3s cubic-bezier(.23,1,.32,1),background .3s cubic-bezier(.23,1,.32,1),transform .6s cubic-bezier(.86,0,.07,1);  background:hsla(0,0%,100%,.4); margin:3px; padding:30px; position: relative; text-align: center; }
                           figure:hover { box-shadow:0 10px 40px 0 rgba(0,0,0,.06); transform:scale(1.05); z-index:1; }
                           img       { cursor:pointer; filter: grayscale(100%) brightness(70%) contrast(2); max-width:200px; }
                           img:hover { filter:none; }
                           .grid-x   { margin-bottom: 20px; }
                           a:hover { opacity: 1; }
              }

.bannerdark     { background: $color-navy; margin-left:40px; min-width: 330px; padding:40px; text-align: center;
                header h3 { color:$color-white; font-size:46px; margin:30px 0 0 0!important; }
                p         { color:$color-white; display: inline-block; margin-top:0; text-align: center; }
                }

#section-header { background:$colorSecondary; background-size: cover; position:relative; /* z-index: -1; */
                figure      { height:625px; overflow: hidden; }
                img         { width:100%; }
                .grid-container { margin-top:230px; }
                header      { margin-top:0; top:0; }
                header h1   { color:$color-white; font:600 48px $font-main; text-align:center;
                            span  { color:$color-white; display: block; font:600 18px $font-proxima; margin:1em 0; text-transform: uppercase; }
                            }
                header h2   { color:$color-white; font:400 28px $font-proxima!important; letter-spacing: -0.04em; text-align:center; text-transform:none; }
                .grid-container { left:0; max-width:none; position:absolute; top:0; width:100%; }
                ul          { margin:20px auto 0; text-align: center;
                            li { display: inline; margin: 0 10px;
                               a { min-width: 140px; }
                               .btnPrimary:hover { background: $colorPrimary; color:$color-white!important; opacity:1; }
                               }
                            }
                }

p.entradeta     { font-size:18px; margin:0 0 3em 0; text-align: center; }

#blocs         {
              .content { margin:0; max-width: none; padding:50px 40px; }
               a     { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
               aside { margin:0 0 100px 0!important;
                     p { font-size: 16px; line-height: 26px; margin: 1em 0; }
                     }
               hr    { border:none; margin:50px 0; }
               ul li { box-shadow:0 2px 4px 0 rgba(0,0,0,.04); background: $color-white; margin-top:25px; padding:0; position: relative;
                     &:before { background:none; }
                     figure { text-align: right;
                            img { margin:0 -15px 0 0; object-position:center top; width: 100%; }
                            }
                     header h4 { font-size: 32px; font-family: $font-main; text-transform:uppercase; }
                     header h5 { font-size: 46px; font-family: $font-main; text-transform:uppercase; }
                     ul    { margin:0 0 30px 0;
                           li       { box-shadow:none; font-size: 16px; line-height: 26px; margin:0; padding:0 0 0 25px; position: relative;
                                    ::before { background: #0081cd; border-radius: 3px; content: ""; display: block; height: 6px; left: 5px; position: absolute; top: 10px; width: 6px; }
                                    p        { margin:0; }
                                    }
                           }
                     }
               .plus { align-items: center; border: 1px solid #fccf00; border-radius: 25px; display: flex; height: 50px; justify-content: center; margin-top: 0; margin-top: 30px; position: absolute; right: 0; top: 50%; transform: translateY(-50%); width: 50px; }
               }

ul.list        { margin-bottom:20px; width:100%;
               li        { background:none; font-size: 16px; line-height: 26px; margin: 1em 0; padding:0 0 0 25px; position: relative;
                         ::before { background: #0081cd; border-radius: 3px; content: ""; display: block; height: 6px; left: 5px; position: absolute; top: 10px; width: 6px; }
                         a        { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
                         }
               }

p.panel        { border-top: 1px solid rgba(0,0,0,.1); font:600 18px $font-proxima; line-height: 24px; padding:20px 0;
               a   { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
               }

ol             { margin-bottom:40px!important; margin-top:20px!important;
               li  { background:none!important; box-shadow: none!important; margin:0!important; padding:5px 0!important; }
               p   { box-shadow: none; margin-bottom:0!important; }
               ::marker { color:#0081cd; font-weight:700; font-size:16px; text-align:center; }
               }

div.panel      { background:$color-white; border: 1px solid rgba(0,0,0,.1); line-height: 24px; padding:30px 40px; text-align: center; width:100%;
               header h3     { font:400 14px $font-proxima; margin:0; text-transform: uppercase; }
               p:first-child { margin:0; }
               }

div.panelinfo  { background:$color-white; border: 1px solid rgba(0,0,0,.1); padding:40px 40px; text-align: center; width:100%;
               p             { font:400 18px $font-proxima; letter-spacing: -0.02em; line-height: 30px; margin:0; text-align: center; }
               p:first-child { margin:0; }
               p strong      { font-weight: 600; }
               }

.slick-prev    { border-radius:30px; border:1px solid $color-white; height:52px; left:-4rem; top:8.5rem; width:52px; z-index:9; }
.slick-next    { border-radius:30px; border:1px solid $color-white; height:52px; right:-4rem; top:8.5rem; width:52px; z-index:9; }


.callout           { font:600 18px $font-main; margin:60px 0 0; text-transform: uppercase;
                   .close-button { color: $colorPrimary; font:400 38px $font-proxima; }
                   }
.callout.alert     { font:400 28px $font-proxima!important;
                   .close-button { color:$color-white!important; font:400 38px $font-proxima; }
                   }

#videohome         { margin-top:-3px;  }

#captcha-ko        { display: inline-block; border: none; font-size:16px!important; margin: 40px 0 0 0; padding: 20px 40px!important; text-align:center; }
.g-recaptcha       { margin:20px 0; }
#infoForm .legal   { margin:20px 0 0 0;
                   input { display: inline-block; margin-bottom:0!important; }
                   label { max-width:95%; }
                   }

.moovitapp         { text-align: left!important; width: auto!important;
                   .moovit  { margin:0 18px!important; }
                   }

.slick-disabled    { display: none!important; }



/* ------------------------------------------------------------------------------------------------------ */
/* SLIDER */
/* ------------------------------------------------------------------------------------------------------ */

.slider             { background:$color-navy; display: inline-block; margin:-4px 0 0 0; height:100%; width:100%;
                    div [role="main"] { max-width: 35em; padding-top: 350px; position: relative; z-index: 99;
                                      span { border-left: 1px solid hsla(0,0%,100%,.3); color: hsla(0,0%,100%,.7); font-size: 13px; margin-left: 9px; padding: 2px 0 2px 9px; }
                                      a    { border-bottom: 2px solid $color-sunflower; color: $color-white; display: inline; font: 600 12px $font-proximasemibold; line-height: 1.2em; text-transform: uppercase; }
                                      div  { margin-bottom:30px; }
                                      p.subtitle { color: $color-white; font-size:18px; text-transform: none; }
                                      a p  { color:$color-white; text-transform: none!important; }
                                      }
                    /* div [role="main"]::after { background-image:linear-gradient(-90deg,rgba(0,21,33,0),#001521); content: ""; display: block; height: 660px; left:auto; position: absolute; top: 0; width: calc(100% + 50px); z-index: 1; } */
                    div [role="article"] { box-sizing: border-box; height: 740px; padding-bottom: 90px; position: relative;
                                      h1  { color:$color-white; font-size:48px; }
                                      img { height: 100%; left:auto; margin:0 8.7rem!important; object-fit: cover; position: absolute; right:calc((1280px - 100vw)/2 - 75px); top: 0; width:calc(100vw - (100vw - 1280px)/2); z-index: 0; }
                                      }
                    .slick-slide { background:$color-navy; }
                    .slick-prev  { border-radius:30px; border:1px solid $color-white; height:52px; left:20rem; top:30rem; width:52px; z-index:9; }
                    .slick-next  { border-radius:30px; border:1px solid $color-white; height:52px; left:24rem; top:30rem; width:52px; z-index:9; }
                    }




@media only screen and (min-width: 900px) and (max-width: 1440px) {
}

@media only screen and (min-width: 640px) and (max-width: 900px) {
#navigation ul#links li .btnPrimary{ font-size:10px!important; min-width: inherit!important; padding:1.23em .8em 1.2em!important; }
#navigation                       { padding:0 5px!important; }
#mainnav a                        { font-size:11px!important; }
#navigation ul#links              { margin:15px 0 0!important; }

}

@media only screen and (min-device-width : 1050px) and (max-device-width : 1650px) {
}


@media only screen and (min-width: 466px) and (max-width : 640px) {
  #topbar           { height: inherit!important; position: fixed; top: 0;
                    #dinamicsponsors { padding:0; }
                    #navigation { min-height:inherit; padding:0; }
                    ul.sponsors        { padding:6px 0; position: absolute; right:20px; text-align: center; top:0; width:auto;
                                       img { width:48px!important; }
                                       }
                    figure#logo-mobile { margin:28px 0 0 -20px; }
                    #emblem            { text-align: center;
                                       img        { margin:0 0 0 -40px; max-width: 52px; }
                                       }
                    #language_selector          { left:0; position:absolute; top:-10px;
                                                nav a      { height:40px; }
                                                nav li > a { padding: 0px 15px 0; }
                                                nav li > a:after { right: -2px; top: 30px; }
                                                nav ul ul { right: inherit; top:55px; }
                                                .dropdown li.is-dropdown-submenu-parent > a { padding:23px 25px 23px 10px; }
                                                }
                    .off-canvas-content .button { position:absolute; right:10px; top:47px; }
                    #btnsmobile                 { background: $color-sunflower!important; margin:12px 0 0 0; width:100%; }
                    #links                      { background: $color-sunflower; float:none!important; margin:0!important; padding:0; text-align: center; width:100%;
                                                li { border-right:1px solid rgba(0,0,0,.2); padding:0!important; width:33.3%!important;
                                                   .btnPrimary { border-radius:0; padding:5px 0!important; width:100%;
                                                               span { font-size:0; }
                                                               }
                                                   }
                                                li:last-child { border:none; }
                                                }
                    .off-canvas-content .button i { font-size:1.5em; }
                    .drilldown                  { min-height:400px; }
                    .drilldown a                { font-size:14px; padding:0.5rem 0; }
                    .drilldown .js-drilldown-back a { font-size:0; padding:10px 20px; }
                    #topcorp                    { background: #fff; margin-top: 45px; width:100%; }
                    .newmainnav                 { padding:0; }
                    }
                    .slider div [role="main"]              { padding-left:20px; padding-top: 420px; }
                    .slider div [role="article"] img       { height:auto!important; margin:0 -10px!important; min-height: 750px; right:0!important; width:100%!important; }
                    .slider .slick-prev { left:30px; }
                    .slider .slick-next { left:90px; }
                    .slider .slick-next, .slider .slick-prev { top:40rem; }

}



/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none;
}



h1 { font-size:120px; }





#language_selector            { float: right; height: 32px !important; }
#language_selector nav li > a { padding:5px 20px 0; }
#language_selector nav li > a:after { content:''; border-color: #fff transparent transparent!important; border: 5px inset; border-bottom-width: 0; border-top-style: solid; display: inline-block; width: 0; height: 0; position: absolute; right: 5px; top:34px; }
#language_selector nav li > a:only-child:after { content: ''; }
#language_selector nav li > a:hover { background:rgba(0,0,0,.05); }
#language_selector nav a     { height:75px; text-align:center; }
#language_selector nav ul ul { top:75px; z-index: 99999; }
#language_selector nav ul ul li a  { background: #000; color: #fff; font-size: 13px; font-weight: 600; height: auto; line-height:40px; padding:0 10px; text-align: left; text-transform: uppercase; }
#language_selector nav ul ul li a:hover  { background: #000; color: #fff; font-weight:800; opacity:0.9; }
#language_selector nav ul ul li a img    { margin:0 10px 0 0; min-width: 18px; }
#language_selector nav ul ul li a:after { border:none!important; }
#language_selector nav li > a img { min-width: 18px; }

#navs  {  width:100%; z-index:999; }
#logos { margin:0 auto 0;
       img { max-width:60%; }
       li  { text-align: center; }
       li:first-child { padding-right:20px; text-align:right; }
       li:last-child  { padding-left:20px; text-align:left;
                      img { margin-top:-5px; }
                      }
       }
.navbar-nav { border-top: 1px solid rgba(255,255,255,.2); display: inline!important; margin:20px 0 0!important; padding:10px 0; text-align: center; margin:0 auto;
            li { display: inline;
               a       { font:16px $font-main; text-transform: uppercase; width:auto; }
               a:hover { color:#0081CD!important; opacity: 1; }
               a:focus { color:#0081CD!important; opacity: 1; }
               }
            }

.icons  { display: inline-block; margin:20px 0 30px!important; width: 100%;
        li  { text-align: center; width:48px!important; }
        img { max-width:32px; }
        }


aside#como-llegar { background: #f2f5f7; display: inline-block; margin-top: 0; padding-bottom:60px; width: 100%;
                  header h2  { color: #001521; font: 600 32px $font-main!important; margin:inherit!important; text-align: center; }
                  a          { color: #001521; }
                  p.subtitle { margin:0 auto; max-width:75%; text-align: center; }
                  .box       { background: #f2f5f7; display: inline-block; margin-top: -90px; max-width: inherit; padding: 60px 80px 0 60px; position: relative; width: 100%;
                             ul { align-items: center; border-bottom: 1px solid #d9dcde; display: flex; justify-content: center; margin: 0 0 40px; padding: 40px 0!important;
                                li { background: none; box-shadow: none; display: flex; flex-direction: row;margin: 0; padding: 0; }
                                }
                             }
                  .transport { color: rgba(0,21,33,.4); display: block; font-size: 12px; font-weight: 600; margin: 0 0 5px; text-transform: uppercase; }
                  figure img { height: 100%; max-height: 460px; object-fit: cover; width: 100%; }
                  li         { color: #001521; font-size: 16px; text-align: left; }
                  li img     { max-width: 32px; }
                  header h5  { font: 12px $font-proxima; margin: 10px 0 0!important; opacity: .4; text-align: left; text-transform: uppercase; }
                  p          { color: #001521; font: 400 16px $font-proxima; line-height: 1.5; margin: 10px 0; text-align: left; }
                  .moovitapp .moovit { margin: 0 18px !important; }
                  p a        { border-bottom:2px solid $colorSecondary; color:$colorSecondary!important; font-weight:600!important; }
                  ul#transportes {
                                 li { margin:0 10px; position: relative;
                                    a { background: #fff!important; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); padding:20px; width:100%; }
                                    img { float:left; margin:0 10px 0 0; }
                                    span{ color:$colorSecondary; }
                                    i.fas{ position: absolute; right: 20px; top: 30px; }
                                    }
                                 li.tram a  { padding:17px 20px; }
                                 li.fgc a   { padding:13px 20px; }
                                 }
                  p a.btn { border-bottom:none; color:#fff!important; margin:20px 0;
                          i { margin-right:10px; }
                          }
                  }

#wrappertop  { background:#001521; top:0; position: fixed; width: 100%; z-index:999; }

p a.btn { background:$colorPrimary; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
p a.btn:hover { background:$color-black; opacity: 1; }

.slick-prev::before { color:#000!important; }
.slick-next::before { color:#000!important; }



/* ASIDE UPSELLING COMMON INCLUDE */
aside#upselling   { background:#F2F5F7; display: inline-block; padding:60px 40px 40px; width:100%;
                  p         { color:$colorSecondary; font:400 18px $font-proxima; margin:0 0 20px 0; text-align: center; }
                  #features { margin:50px auto 80px auto;
                            div { background: $color-white; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); border-radius: 30px; margin:0 10px; padding:40px; width:30%;
                                ul { margin:0; min-height: 240px;
                                    li { font:400 18px $font-proxima; padding:5px 0;
                                       strong { color:$colorSecondary; display: inline-block; font:600 18px $font-medium; margin-bottom:10px; }
                                       i.fas  { float:left; margin: 4px 5px 0 0; font-size:12px; }
                                       }
                                    }
                            }
                        div.highlited { background: $color-black; color: $color-white;
                                      p     { color: $color-white; text-align: center !important;}
                                      ul li { color: $color-white;
                                            strong { color: $color-white; }
                                            i { color: $color-white; }
                                            }
                                      }
                        header h3 { font: 600 28px $font-main; line-height: 32px; text-transform: uppercase; }
                        p         { text-align: center !important; }
                        }
              ul  { margin:50px auto 80px auto; max-width: 75rem;
                  li {
                    img       { display: inherit; margin:0 auto 10px; max-width: 75%; }
                    header h3 { color:$colorSecondary; font:400 16px $font-medium; text-align: center; text-transform: uppercase; }
                    }
                 }
              p.btn    { display: inline-block; margin:50px 0 0 0; padding:0; min-height: inherit!important; text-align: center;
                       a { background:#0081CD; border-radius: 30px; color:#fff; font: 600 14px $font-main; padding:10px 20px; text-align: center; text-transform:uppercase; }
                       }
              }

aside#video   { margin:0 auto; max-width:70rem; padding: 60px 0 0; text-align: center; }
